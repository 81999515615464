import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.scss";
import { Landing } from "./components/landing";
import { Imprint } from "./components/imprint";


const router = createBrowserRouter(
  [
    {
      path: "/",
      element: (<Landing/>),
    },
    {
      path: "/imprint",
      element: (<Imprint/>),
    }
  ]
);

export const App = () => {
  
  const date = new Date();
  return (
    <div className="app">
      <div className="navbar d-flex flex-row justify-content-center"><a href="/"><img src="img/euriast_no_border.png" alt="logo" className="logo" /></a></div>
      <RouterProvider router={router} />
      <div className="footer">
        <span>© copyright { date.getFullYear() }</span>
        <span>EURIAST</span>
        <span>
          <a href="/imprint">Impressum</a>
        </span>
      </div>
    </div>
  );
}
