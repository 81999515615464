import "./landing.scss";

export const Landing = () => {
    return (
        <div className="landing">
            <div className="container d-flex flex-column justify-content-center align-items-center">
                <div className="row align-items-start mt-4">
                    <div className="col">
                        <div className="card">
                            <img src="img/office-1209640_1920-pixabay.jpg" className="card-img-top feature-image" alt="akademie" />
                            <div className="card-header feature-header">
                                Akademie
                            </div>
                            <div className="card-body d-flex flex-column">
                                <p>Die eLearning-Plattform "SecureLearn" bietet qualifizierte und praxisnahe Kurse in folgenden Bereichen:</p>
                                <ul>
                                    <li>Sicherheit</li>
                                    <li>Sicherheitstechnik</li>
                                    <li>Resilienz</li>
                                    <li>Geopolitik</li>
                                    <li>Krisenvorsorge</li>
                                    <li>Survival</li>
                                    <li>Selbstverteidigung</li>
                                </ul>
                                <p>Wir bieten ein Einzigartiges Curriculum, das eLearning, Präsenzunterricht und Praxiserfahrung kombiniert.</p>
                                <p>Angebote für eine breite Zielgruppe, darunter Bundeswehrangehörige, Sicherheitsunternehmen, Sportschützen, Berufswaffenträger und die allgemeine Bevölkerung.</p>
                                <p>Unser Ziel ist die Schaffung eines umfassenden Bildungserlebnisses, das individuellen Bedürfnissen und beruflichen Anforderungen gerecht wird.</p>
                                <hr />
                                <button className="construction btn btn-primary btn-lg mx-auto">Im Aufbau</button>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card">
                            <img src="img/euriast-eurodh-55.jpg" className="card-img-top feature-image" alt="akademie" />
                            <div className="card-header feature-header">
                                Waffenhandel
                            </div>
                            <div className="card-body d-flex flex-column">
                                <p>Die Übernahme eines traditiunsunternehmens aus Süddeutschland resultiert in einem neuen Geschäftsmodell mit den folgenden Schwerpunkten:</p>
                                <ul>
                                    <li>Online-Verkauf von Waffen, Ausrüstung und Outdoor-Produkten</li>
                                    <li>Verlagerung des Ladengeschäftes nach Kirchberg a. d. Murr</li>
                                    <li>Jungjägerausbildung</li>
                                    <li>Beratungsdienstleistungen</li>
                                </ul>
                                <p>Wir planen den Verkauf von Outdoor-Produkten über das Internet. Statistiken zeigen, dass der Online-Verkauf von Waffen und Ausrüstung einen positiven Trend verzeichnet.</p>
                                <p>Unser Team leistet Unterstützung bei Erbschaftsauflösungen und Jungjägerberatungen.</p>
                                <p>Ein weiterer Schwerpunkt ist die Etablierung eines hochmodernen Trainingszentrums in München für die Jungjägerausbildung</p>
                                <hr />
                                <button className="construction btn btn-primary btn-lg mx-auto">Im Aufbau</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}